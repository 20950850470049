import React, { useEffect } from 'react';

function Banner() {
  useEffect(() => {
    const parallaxElm = document.querySelectorAll('.layer');
    const damping = 0.05;
    
    let targetX = 0;
    let targetY = 0;
    let currentX = 0;
    let currentY = 0;
    let animationFrameId = null;
    let isAnimating = false;

    const handleMouseMove = (e) => {
      targetX = e.clientX - window.innerWidth / 2;
      targetY = e.clientY - window.innerHeight / 2;
      
      if (!isAnimating) {
        isAnimating = true;
        animationFrameId = requestAnimationFrame(update);
      }
    };

    const update = () => {
      // Smooth interpolation
      currentX += (targetX - currentX) * damping;
      currentY += (targetY - currentY) * damping;
      
      const rotationAmount = (currentX / (window.innerWidth / 2)) * 20;

      parallaxElm.forEach((element) => {
        const speedx = parseFloat(element.dataset.speedx) || 1;
        const speedy = parseFloat(element.dataset.speedy) || 1;
        const rotation = parseFloat(element.dataset.rotation) || 0;
        
        // Check if this is layer-5
        if (element.classList.contains('layer-5')) {
          // Only apply horizontal translation for layer-5
          const translateX = `${-currentX * speedx}px`;
          element.style.transform = `translateX(${translateX})`;
        } else {
          // Apply full 3D transform for other layers
          const translateX = `${-currentX * speedx}px`;
          const translateY = `${currentY * speedy}px`;
          element.style.transform = `translateX(${translateX}) translateY(${translateY}) perspective(2300px) rotateY(${rotationAmount}deg) rotate(${rotation}deg)`;
        }
      });

      // Only continue animation if there's still significant movement
      if (Math.abs(targetX - currentX) > 0.01 || Math.abs(targetY - currentY) > 0.01) {
        animationFrameId = requestAnimationFrame(update);
      } else {
        isAnimating = false;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  const scrollToNextSection = () => {
    const nextSection = document.querySelector('#banner_section').nextElementSibling;
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="banner_section">
      <div className="banner-container">
        
        <div id="parallax">
          <div className="layer layer-5" data-speedx="0.15" data-speedy="0.15" data-rotation="0.02">
            <div className="parallax-image" />
          </div>
          <div className="layer layer-4" data-speedx="0.125" data-speedy="0.125" data-rotation="0.018">
            <div className="parallax-image" />
          </div>
          <div className="layer layer-3" data-speedx="0.1" data-speedy="0.1" data-rotation="0.016">
            <div className="speech-bubble">
              <h1>
                Hey There!
              </h1>
              <p>
                I'm Clayton, the abnormally tall Visual Artist from Utah.
              </p>
            </div>
            <div className="parallax-image" />
          </div>
          <div className="layer layer-2" data-speedx="0.075" data-speedy="0.075" data-rotation="0.014">
            <div className="parallax-image" />
          </div>
          <div className="layer layer-1" data-speedx="0.05" data-speedy="0.05" data-rotation="0.012">
            <div className="parallax-image" />
          </div>
        </div>
        <div id="hero_mobile" />
        
        <div className="scroll-indicator" onClick={scrollToNextSection}>
          <span className="material-icons bounce">expand_more</span>
          <small>see more</small>
        </div>
      </div>
    </section>
  );
}

export default Banner;
