import React, { useState, useEffect, useRef } from 'react';
import { portfolio } from './portfolioData';
import Modal from './Modal';
import { initializeAnimations } from '../utils/animationObserver';

const Portfolio = () => {
  const [currentFilter, setCurrentFilter] = useState('Featured');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalIndex, setModalIndex] = useState(0);
  const [filteredPortfolio, setFilteredPortfolio] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);

  // Add a ref to track the current animation timeout
  const animationTimeoutRef = useRef(null);

  // Add ref for the section
  const sectionRef = useRef(null);

  // At the top with other refs
  const previousFocusRef = useRef(null);

  useEffect(() => {
    // Set the initial filter to 'Featured' when the component mounts
    handleFilter('Featured');
  }, []); // The empty dependency array ensures this effect runs only once

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          const items = document.querySelectorAll('.portfolio-list--item');
          
          items.forEach(item => {
            if (entry.isIntersecting) {
              item.classList.remove('filter-exit');
              // Force reflow
              void item.offsetWidth;
              item.classList.add('filter-enter');
            } else {
              item.classList.remove('filter-enter');
              item.classList.add('filter-exit');
            }
          });
        });
      },
      {
        threshold: 0.2 // Trigger when 20% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [filteredPortfolio]); // Re-run when filtered items change

  useEffect(() => {
    // Initialize animations when component mounts
    const cleanup = initializeAnimations();
    return cleanup;
  }, [filteredPortfolio]); // Re-run when filtered items change

  const handleFilter = (category) => {
    // Clear any pending animation timeouts
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }

    // Update state immediately
    setCurrentFilter(category);
    const newFilteredItems = portfolio.filter((item) => 
      category === 'all' ? true : item.categories.includes(category)
    );
    setFilteredPortfolio(newFilteredItems);

    // Handle animations
    requestAnimationFrame(() => {
      const items = document.querySelectorAll('.portfolio-list--item');
      items.forEach(item => {
        item.classList.remove('filter-exit', 'filter-enter');
        // Force a reflow
        void item.offsetWidth;
        item.classList.add('filter-enter');
      });

      // Clean up classes after animations complete
      animationTimeoutRef.current = setTimeout(() => {
        items.forEach(item => {
          item.classList.remove('filter-enter');
        });
      }, 1400); // Reduced from 2000ms (20 items * 0.05s delay + 0.4s animation)
    });
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, []);

  const openModal = (item, index) => {
    // Store the currently focused element
    previousFocusRef.current = document.activeElement;
    
    // Set modal content synchronously
    setModalItem(item);
    setModalIndex(index);
    setIsModalOpen(true); // Remove the requestAnimationFrame here
  };

  // Add this new useEffect to manage focus
  useEffect(() => {
    if (isModalOpen && modalItem) {
      const observer = new MutationObserver((mutations, obs) => {
        const modalElement = document.querySelector('.modal');
        if (modalElement) {
          const focusableElements = modalElement
            .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
          if (focusableElements.length) {
            focusableElements[0].focus();
            // Force focus if the first attempt didn't work
            requestAnimationFrame(() => {
              if (document.activeElement !== focusableElements[0]) {
                focusableElements[0].focus();
              }
            });
          }
          obs.disconnect(); // Stop observing once we've found the modal
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });

      return () => observer.disconnect();
    }
  }, [isModalOpen, modalItem]);

  const closeModal = () => {
    setIsModalOpen(false);
    // Return focus to the previous element
    if (previousFocusRef.current) {
      previousFocusRef.current.focus();
    }
  };

  const goToNextItem = () => {
    if (modalIndex < filteredPortfolio.length - 1) {
      const nextIndex = modalIndex + 1;
      setModalItem(filteredPortfolio[nextIndex]);
      setModalIndex(nextIndex);
    }
  };

  const goToPreviousItem = () => {
    if (modalIndex > 0) {
      const previousIndex = modalIndex - 1;
      setModalItem(filteredPortfolio[previousIndex]);
      setModalIndex(previousIndex);
    }
  };

  // Add this new function near your other handlers
  const handleModalKeyDown = (e) => {
    if (!isModalOpen) return;

    const modalElement = document.querySelector('.modal');
    if (!modalElement) return;

    const focusableElements = modalElement
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    // Handle Tab and Shift+Tab
    if (e.key === 'Tab') {
      if (e.shiftKey) { // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else { // Tab
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }

    // Handle Escape key
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  // Add this useEffect to set up the event listener
  useEffect(() => {
    if (isModalOpen) {
      // Add event listener when modal opens
      document.addEventListener('keydown', handleModalKeyDown);
    }
    
    // Clean up
    return () => {
      document.removeEventListener('keydown', handleModalKeyDown);
    };
  }, [isModalOpen]);

  return (
    <section 
      ref={sectionRef}
      id="portfolio_section" 
      className="border-bottom pt-5 bg-white"
    >
      <div className="content-container">
        <h2 className="section-heading">Portfolio</h2>
        <div className="filter-buttons">
        <button
          onClick={() => handleFilter('Featured')}
          className={currentFilter === 'Featured' ? 'selected' : ''}>
          Featured
        </button>
        <button
          onClick={() => handleFilter('Work')}
          className={currentFilter === 'Work' ? 'selected' : ''}>
            Work
        </button>
        <button
          onClick={() => handleFilter('Fun')}
          className={currentFilter === 'Fun' ? 'selected' : ''}>
            Fun
        </button>
        </div>
        <ul className="portfolio-list">
          {filteredPortfolio.map((portfolioItem, index) => {
            return (
              <li
                key={portfolioItem.id}
                className="portfolio-list--item"
                data-animate
                className={`portfolio-list--item flip-vertical delay-${index + 1}`}
                data-id={portfolioItem.id}
              >
                <a tabIndex="0"
                  onClick={() => openModal(portfolioItem, index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault(); // Prevent page scroll on space
                      openModal(portfolioItem, index);
                    }
                  }}
                >
                
                  <div className="item-inner">
                    <div
                      className="item-front"
                      style={{
                        backgroundImage: `url('${portfolioItem.thumbnail}')`,
                        backgroundSize: 'cover',
                      }}
                    />
                    <div className="item-back text-white text-center">
                      <div className="center-it w-100">
                        <h3>{portfolioItem.title}</h3>
                        <p>{portfolioItem.subtitle}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <Modal
          isOpen={isModalOpen}
          closeModal={closeModal}
          item={modalItem}
          modalIndex={modalIndex}
          filteredPortfolio={filteredPortfolio}
          goToNextItem={goToNextItem}
          goToPreviousItem={goToPreviousItem}
          aria-modal="true"
          role="dialog"
        />
    </section>
  );
};

export default Portfolio;