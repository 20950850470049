import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import './scss/cw-styles.scss';
import Header from './components/Header';
import Banner from './components/Banner';
import Portfolio from './components/Portfolio';
import Carousel from './components/Carousel';
import AboutMe from './components/AboutMe';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

// Import your case study components
import CaseStudy1 from './components/CaseStudy1';
import CaseStudy2 from './components/CaseStudy2';
import CaseStudy3 from './components/CaseStudy3';

function App() {
  console.log(
    "%cWelcome to my portfolio!\n" +
    "%cFeel free to explore the code, just don't judge my variable names too harshly 😅",
    "color: #308273; font-size: 20px; font-weight: bold;",
    "color: #222222; font-size: 16px;",
  );

  const scrollToSection = (sectionId) => {
    // Small delay to ensure the home page components are mounted
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <div id="page_content_container">
      <BrowserRouter>
        <Header scrollToSection={scrollToSection} />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Banner />
                <Portfolio />
                {/* <Carousel /> */}
                <AboutMe />
              </>
            }
          />
          <Route path="/case-studies/*" element={<CaseStudies />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

function CaseStudies() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="1" />} />
      <Route path="1" element={<CaseStudy1 />} />
      <Route path="2" element={<CaseStudy2 />} />
      <Route path="3" element={<CaseStudy3 />} />
    </Routes>
  );
}

export default App;
