import React from 'react';

function CaseStudy2() {
  return (
    <section id="caseStudy_aiCollaboration" className="bg-light">
      <div className="bannerArea">
        <div className="bannerContent">
          <h1>
            AI Stuff
          </h1>
          <p>
            WOOOOO
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-md-7">
            <h2>About</h2>
            <p>
              As Director of Global Design and Experience at InMoment, I've had the opportunity to lead the team at the forefront of a project that embodies the core principles of user-centered design, accessibility, and innovation. Together we were able to produce four groundbreaking tools that have not only streamlined survey and email campaign creation but have helped to take accessibility and user experience to new heights at InMoment.
            </p>
            <h2>The Challenge</h2>
            <p>
              My team specializes in creating unique survey themes (a collection of custom html, less/css, and js) and email campaigns for a wide range of note regularly delivers compelling user experiences that are tailored to fit the established brand identity of each client. Over the years we had developed a number of custom features that were not yet implemented within our core platform and wanted to provide a way of showcasing these solutions, as well as streamline the process of survey theme and email campaign creation.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <div>
              <h3>Project Components:</h3>
              <ul>
                <li>Demo Showcase Survey</li>
                <li>Survey Theme Builder</li>
                <li>Email Campaign Builder</li>
                <li>Feedback Survey</li>
              </ul>
            </div>
            <h3>My Role</h3>
            <ul>
              <li>Project Planning</li>
              <li>Writing User Guides</li>
              <li>Design & Development</li>
              <li>Announcement & Deployment</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudy2;