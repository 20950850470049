import React, { useRef, useEffect, useState } from 'react';

const Modal = ({ isOpen, closeModal, item, filteredPortfolio, goToNextItem, goToPreviousItem, modalIndex }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [direction, setDirection] = useState(null);
  const [transitioning, setTransitioning] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      document.body.style.overflow = 'hidden';
      requestAnimationFrame(() => {
        setIsVisible(true);
        const closeButton = modalRef.current?.querySelector('.modal-close');
        if (closeButton) {
          closeButton.focus();
        }
      });
    } else {
      document.body.style.overflow = '';
      setIsVisible(false);
    }
  }, [isOpen]);

  const handleTransitionEnd = (e) => {
    if (e.target === modalRef.current && !isOpen) {
      setIsAnimating(false);
      setDirection(null);
    }
  };

  const handleModalClick = (e) => {
    if (modalRef.current && e.target === modalRef.current) {
      closeModal();
    }
  };

  const handleNextItem = () => {
    setTransitioning(true);
    setDirection('next');
    setTimeout(() => {
      goToNextItem();
      setTransitioning(false);
      setDirection(null);
    }, 300);
  };

  const handlePreviousItem = () => {
    setTransitioning(true);
    setDirection('prev');
    setTimeout(() => {
      goToPreviousItem();
      setTransitioning(false);
      setDirection(null);
    }, 300);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const trapTabKey = (event) => {
    // If Tab key is pressed, manage focus inside the modal
    if (event.key === 'Tab') {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (event.shiftKey) {
        // If Shift + Tab, move focus to the last focusable element inside the modal
        if (document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        }
      } else {
        // If Tab, move focus to the first focusable element inside the modal
        if (document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    }
  };

  if (!isOpen && !isAnimating) return null;

  return (
    <div 
      className={`portfolio-modal ${isVisible ? 'modal-visible' : 'modal-hidden'}`} 
      onClick={handleModalClick} 
      ref={modalRef} 
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      onKeyDown={trapTabKey}
      onTransitionEnd={handleTransitionEnd}
    >
      {(isVisible || isAnimating) && (
        <div className="portfolio-modal-panel">
          <div className={`panel-content ${direction ? 'transitioning' : ''} ${direction || ''}`}>
            <button 
              className="modal-close" 
              onClick={closeModal}
              aria-label="Close modal"
            >
              close
            </button>
            <div
              className="modal-image-container"
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: item.bgSize ? item.bgSize : 'cover',
                backgroundColor: item.bgColor ? item.bgColor : 'transparent',
              }}
            ></div>
            <div className="portfolio-modal-content">
              <h1 id="modal-title">{item.title}</h1>
              <p>{item.subtitle}</p>
              <p dangerouslySetInnerHTML={createMarkup(item.description)} />
            </div>
          </div>
          
          <div className="modal-navigation-buttons">
            <button 
              className="previous-modal" 
              onClick={handlePreviousItem} 
              disabled={modalIndex === 0}
            >
              arrow_back_ios_new
            </button>
            <button 
              className="next-modal" 
              onClick={handleNextItem} 
              disabled={modalIndex === filteredPortfolio.length - 1}
            >
              arrow_forward_ios
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
