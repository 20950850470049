export const initializeAnimations = () => {
  const animatedElements = document.querySelectorAll('[data-animate]');
  
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
        } else {
          entry.target.classList.remove('animate-in');
        }
      });
    },
    {
      threshold: 0.2,
      rootMargin: '0px'
    }
  );

  animatedElements.forEach(element => {
    observer.observe(element);
  });

  return () => {
    animatedElements.forEach(element => {
      observer.unobserve(element);
    });
    observer.disconnect();
  };
}; 