import React from 'react';
import LanguageSelectionImage from '../img/case-studies/cw-max-languageSelection.jpg';
function CaseStudy3() {
  const processSteps = [
    { title: "Discovery", description: "Research & Analysis" },
    { title: "Design", description: "UX/UI Solutions" },
    { title: "Development", description: "Implementation" }
  ];

  return (
    <section id="caseStudy_max" className="bg-light">
      <div className="bannerArea">
        <div className="bannerContent">
          <h1>
            The Max Digital Ecosystem
          </h1>
          <p>
            Company: Max International
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-md-7">
            <h2>About</h2>
            <p>
              Max International specializes in glutathione-enhancing products, emphasizing health and wellness. Through a network marketing model, independent Associates distribute Max's products and drive business growth, earning income based on sales within their teams
            </p>
            <h2>The Challenge</h2>
            <p>
              With a global presence, their sites and services needed to be capable of operating in numerous countries and languages while accounting for varying product availability across markets. I was tasked with the challenge of redesigning their digital ecosystem to improve user experiences and facilitate product sales. The complexity lay in streamlining the digital platforms to cater to the needs of both Associates and customers, ensuring a seamless experience across various touchpoints.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <div>
              <h3>Project Components:</h3>
              <ul>
                <li>The Company Website</li>
                <li>The Virtual Office</li>
                <li>Max Mobile App</li>
              </ul>
            </div>
            <h3>My Role</h3>
            <ul>
              <li>Research and analysis</li>
              <li>User Flows</li>
              <li>App Design</li>
              <li>Art Direction</li>
              <li>Prototyping</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
            <div className="process-timeline">
              {processSteps.map((step, index) => (
                <div key={index} className="process-step">
                  <div className="step-number">{index + 1}</div>
                  <h3>{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>The Process:</h2>
            <h3>Research and Analysis</h3>
            <p>
              We began by conducting comprehensive research into Max's existing digital ecosystem and assessed potential challenges. This included an analysis of competitors and major online shopping experiences to identify best practices and pitfalls to avoid.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            
            <img alt="Example of a language selection page" className="social-icon" src={LanguageSelectionImage} />

            <h3>Adapting to User Needs</h3>
            <p>
              We recognized the importance of developing a solution that could seamlessly accommodate the diverse needs of Max International's global user base, spanning multiple countries, languages, and account types. For instance, while a Max Associate may prioritize access to events, training guides, and commission reports to support their business endeavors, a customer primarily seeking nutritional benefits from product purchases may have different priorities. While this presented a unique challenge, it also presented opportunities to deliver tailored information where it can have the greatest impact.
            </p>
            <p>
              To address this, the improved user experience introduced several enhancements aimed at enhancing overall adaptability, including:
              <ul>
                <li>Modular approach to content management</li>
                <li>Improved enrollment flow to better balance the need for a sponsor id# and user convenience, addressing a significant challenge.</li>
                <li>Created a language selection landing page, ensuring a tailored experience from the outset.</li>
              </ul>
              <br />
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-12 col-md-6">
            <h2>The Virtual Office (VO) Redesign</h2>
            <p>
              The previous Max Virtual Office was in need of reorganization to enhance the user experience. Throughout this project, reports were clarified, and we improved the system's functionality for better usability.
            </p>
            <ul>
              <li><strong>Clear navigation:</strong> We established a smaller list of navigation items for a more intuitive organization, making it easier for users to locate essential information.</li>
              <li><strong>Enhanced reports:</strong> We improved the clarity of reports, providing context to help Associates understand their relevance to their businesses.</li>
              <li><strong>Training reimagined:</strong> Training was restructured into smaller, digestible courses, each focusing on specific topics, with clear progressions.</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <div className="size--3x2 bg-color--primary">
              <p>-- Insert Images of Commission Reports --</p>
            </div>
          </div>
        </div>
      </div>
      <div class="highlightSection bg-color--lightGray">
        <div class="container">
          <div class="row py-5">
            <div className="col-12">
              <h2>
                The Max App
              </h2>
              <p>
                We worked closely with Max's Sales and Marketing teams to redesign the Max App, taking into account user feedback and expectations. The goal was to create a more user-friendly and feature-rich companion app to act as both a lead generation and prospecting tool.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
            <h2>
              Outcome
            </h2>

            <p>
              The prototypes created for these various products were well-received and later implemented by the development team. This project emphasized the importance of understanding the unique purpose of each of these tools while tailoring the user experience to different account types and ensuring the on-screen content remained relevant to users.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudy3;