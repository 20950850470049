import React from 'react';

function CaseStudy1() {
  return (
    <section id="caseStudy_inMoment" className="bg-light">
      <div className="bannerArea">
        <div className="bannerContent">
          <h1>
            Revolutionizing Survey Tools at InMoment
          </h1>
          <p>
            Building Enterprise-Grade Tools for Survey Design, Accessibility, and User Experience
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-md-7">
            <h2>About</h2>
            <p>
              I've had the opportunity to lead the team at the forefront of a project that embodies the core principles of user-centered design, accessibility, and innovation. Together we were able to produce four groundbreaking tools that have not only streamlined survey and email campaign creation but have helped to take accessibility and user experience to new heights at InMoment.
            </p>
            <h2>The Challenge</h2>
            <p>
              My team specializes in creating unique survey themes (a collection of custom html, less/css, and js) and email campaigns for a wide range of note regularly delivers compelling user experiences that are tailored to fit the established brand identity of each client. Over the years we had developed a number of custom features that were not yet implemented within our core platform and wanted to provide a way of showcasing these solutions, as well as streamline the process of survey theme and email campaign creation.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <div className="case-study-image mb-4">
              <img 
                src="/placeholder-hero.jpg" 
                alt="Collection of InMoment survey tools interfaces" 
                className="img-fluid rounded shadow"
              />
            </div>
            <div>
              <h3>Project Components:</h3>
              <ul>
                <li>Demo Showcase Survey</li>
                <li>Survey Theme Builder</li>
                <li>Email Campaign Builder</li>
                <li>Feedback Survey</li>
              </ul>
            </div>
            <h3>My Role</h3>
            <ul>
              <li>Project Planning</li>
              <li>Design & Development</li>
              <li>Announcement & Deployment</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
            <div className="size--4x1 bg-color--primary container">
              <div className="row">
                <div className="col-12">
                  <h2>Striving for continuous improvement</h2>
                </div>
                <div className="col-3">
                  <p>Research & Plan</p>
                </div>
                <div className="col-3">
                  <p>Design & Prototype</p>
                </div>
                <div className="col-3">
                  <p>Development & Testing</p>
                </div>
                <div className="col-3">
                  <p>Deployment & Iteration</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>The Process:</h2>
            <h3>Research and Analysis</h3>
            <p>
              
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <h3>1. Demo Showcase Survey: Redefining Survey Excellence</h3>
            <p>
              The Demo Showcase Survey represents the pinnacle of survey design and functionality.
            </p>
            <p>
              Key features:
            </p>

              <ul>
                <li><strong>Multiple Brands, 1 theme:</strong> Effortlessly demonstrate theme versatility with a single click.</li>
                <li><strong>Prompt-to-Prompt Auto-Advance:</strong> Enhance user interaction with seamless navigation.</li>
                <li><strong>Simplified Navigation:</strong> Intuitive and efficient survey traversal.</li>
              </ul>
              <br />
          </div>
          <div className="col-12 col-md-6">
            <div className="case-study-image">
              <img 
                src="/placeholder-demo-showcase.jpg" 
                alt="Demo Showcase Survey interface showing brand switching capability" 
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <div className="case-study-image">
              <img 
                src="/placeholder-campaign-builder.jpg" 
                alt="Campaign Builder interface with real-time preview" 
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h3>2. Campaign Builder Survey: Simplifying Email Campaign Design</h3>
            <p>
              Craft compelling email campaigns with an interface that prioritizes user-friendliness.
            </p>
            <p>
              Noteworthy features:
            </p>
            <ul>
              <li><strong>Image, Color, and Text Customization:</strong> Tailor email campaigns with precision.</li>
              <li><strong>Real-time HTML Preview:</strong> Witness your email campaign taking shape in real time.</li>
              <li><strong>Efficiency and Customization:</strong> Strike the perfect balance between speed and personalization.</li>
            </ul>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <h3>3. Theme Builder Survey: Empowering User Creativity</h3>
            <p>
            The Theme Builder Survey puts the power of survey theme creation in users' hands without requiring any coding expertise.
            </p>
            <p>
              Key Features:
              <ul>
                <li><strong>Personalized Themes:</strong> Define unique colors, layouts, and preferences.</li>
                <li><strong>@Less Variables:</strong> Empower users with the CSS magic needed for custom themes.</li>
              </ul>
              <br />
            </p>
          </div>
          <div className="col-12 col-md-6">
            <div className="case-study-image">
              <img 
                src="/placeholder-theme-builder.jpg" 
                alt="Theme Builder interface showing color and layout customization options" 
                className="img-fluid rounded shadow"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3>4. Feedback Survey: Measuring Success</h3>
            <p>
              In order to establish a listening post for qualitative as well as quantitative data. The team also created a feedback survey for the purpose of gathering insights into the way that these tools were received. Allowing the feedback to help shape further developments.
            </p>
            <hr />
          </div>
        </div>
        
      </div>
      <div className="highlightSection bg-color--lightGray">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-md-8">
              <h2>The Impact:</h2>
              <p>
                Beyond the impressive features, the heart of this project lies in a commitment to accessibility and inclusivity. These tools are designed to adhere to WCAG standards, ensuring surveys are accessible to all users, regardless of abilities. This focus on inclusivity has not only broadened our reach but has also reinforced InMoment’s position as industry leaders.
              </p>
              <p>
                These tools have not only streamlined the survey creation process, but have also enhanced user experiences. In turn, we're saving time and effort while delivering exceptional results.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="case-study-image">
                <img 
                  src="/placeholder-metrics.jpg" 
                  alt="Visual representation of key metrics and improvements" 
                  className="img-fluid rounded shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12">
            <h2>
              Conclusion: 
            </h2>
            <p>
              I'm thrilled to have played a crucial role in the development of these innovative tools. Together, they have redefined the landscape of survey creation, email campaigns, and user accessibility. Our commitment to accessibility and innovation is at the core of these accomplishments.
              In this portfolio piece, I invite you to explore these tools, dive into their features, and experience their capabilities firsthand. There's no need for feedback here; it's a testament to the passion and dedication of the [Your Company] UI/UX team in setting new industry standards.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudy1;