import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Carousel() {
  const [currentItem, setCurrentItem] = useState(0);
  const items = [
    {
      id: 1,
      title: 'Adaptable and Accessible',
      subtitle: 'The InMoment Survey Design System',
      buttonText: 'Find out more',
      backgroundImage: 'url("/path/to/image1.jpg")',
    },
    {
      id: 2,
      title: 'Is it mine?',
      subtitle: 'Exploration in working with AI',
      buttonText: 'See what I\'ve found',
      backgroundImage: 'url("/path/to/image2.jpg")',
    },
    {
      id: 3,
      title: 'Max',
      subtitle: 'Insights from a site redesign',
      buttonText: 'Read the case study',
      backgroundImage: 'url("/path/to/image3.jpg")',
    },
  ];

  const totalItems = items.length;
  const autoSlideDelay = 5000; // 5 seconds
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem((prevItem) => (prevItem + 1) % totalItems);
    }, autoSlideDelay);

    return () => clearInterval(interval);
  }, [totalItems]);

  const handleBack = () => {
    setCurrentItem((prevItem) => (prevItem - 1 + totalItems) % totalItems);
  };

  const handleNext = () => {
    setCurrentItem((prevItem) => (prevItem + 1) % totalItems);
  };

  const navigateToCaseStudy = (itemId) => {
    navigate(`/case-studies/${itemId}`);
  };

  return (
    <section id="carousel_section">
      <div className="content-container">
        <div className="carousel-container">
          <div className="carousel">
            {items.map((item, index) => {
              const isActive = index === currentItem;
              const isNext = index === (currentItem + 1) % totalItems;
              const isPrev = index === (currentItem - 1 + totalItems) % totalItems; // Determine the previous item
              return (
                <div
                  key={item.id}
                  className={`carousel-item ${isActive ? 'active' : ''} ${isNext ? 'next' : ''} ${isPrev ? 'prev' : ''}`}
                  style={{ backgroundImage: item.backgroundImage }}
                >
                  <div className="carousel-content">
                    <h1>{item.title}</h1>
                    <p>{item.subtitle}</p>
                    <button className="button-primary" onClick={() => navigateToCaseStudy(item.id)}>
                      {item.buttonText}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="carousel-navigation">
          <button className="back-button" onClick={handleBack}>
            arrow_back_ios_new
          </button>
          <button className="next-button" onClick={handleNext}>
            arrow_forward_ios
          </button>
        </div>
      </div>
    </section>
  );
}

export default Carousel;