import React, { useEffect } from 'react';
import { initializeAnimations } from '../utils/animationObserver';
import cwPhoto from '../img/cw_photo.png';

function AboutMe() {
  useEffect(() => {
    const cleanup = initializeAnimations();
    return cleanup;
  }, []);

  return (
    <section id="about_section" className="py-5 bg-light">
        <div className="content-container">
            <div className="row pb-5">
                <div className="col-10 col-md-3 slide-right"
                     data-animate>
                    <img className="w-100" src={cwPhoto} alt="Clayton Whitehead Profile Photo" />
                </div>
                <div className="col-md-9 slide-left"
                     data-animate>
                    <h2 className="mb-3">The weather up here is quite nice</h2>
                    <p>
                      I'm Clayton, a coffee-fueled designer, problem solver, and an multi-faceted artist. With a background in Graphic Design and Illustration, my creative journey has evolved into the dynamic world of UI/UX and product design.
                    </p>
                    <p>
                      I've had the privilege of leading creative teams that seamlessly bridge design and development, resulting in the creation of meaningful user experiences. My passion for visual art extends across multiple mediums, as I've always been fascinated by the fusion of art and technology.
                    </p>
                    <p>
                      Whether it's through pixels or paint, my goal is clear: to create effective, innovative, and visually captivating solutions.
                    </p>
                    <p>
                      Interested in collaborating or simply want to connect? Drop me a line! 
                      <br />
                    </p>
                    <a className="button-primary mt-2" href="mailto: hello@claytonwhitehead.com">hello@claytonwhitehead.com</a>
                </div>
            </div>
        </div>
        <hr />
        <div className="content-container">
            <div className="width-100 c-fix padding-y-xl">
                <div className="row">
                    <div className="col-sm-6 col-md-4 flip-horizontal delay-1"
                         data-animate>
                        <h3 className="font-2 text-uppercase">Graphic Designer</h3>
                        <p>Experienced creating effective designs for both print and web while maintaining brand identity.</p>
                    </div>
                    <div className="col-sm-6 col-md-4 flip-horizontal delay-2"
                         data-animate>
                        <h3 className="font-2 text-uppercase">Front-End Developer</h3>
                        <p>Proficient in HTML5, CSS/SCSS/LESS, and JavaScript with an emphasis on creating accessible, engaging, and responsive web experiences.</p>
                    </div>
                    <div className="col-sm-6 col-md-4 flip-horizontal delay-3"
                         data-animate>
                        <h3 className="font-2 text-uppercase">Illustrator</h3>
                        <p>Ready to bring your creative visions to life using a mix of traditional and digital techniques.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default AboutMe