import React from 'react';
import LinkedInImage from '../img/icons/LinkedIn-Icon-Square.svg';
import InstagramImage from '../img/icons/Instagram-Icon.svg';
import cwNameLogo from '../img/cw_LogoAndName.png';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <section id="footer_section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center py-5">
            <img src={cwNameLogo} alt="CW Logo" />
            <br />
            <div className="social-icons py-3">
              <a className="social-link" target="_blank" href="https://www.linkedin.com/in/clayton-whitehead-a2bb29b8/">
                <img alt="Connect on LinkedIn" className="social-icon" src={LinkedInImage} />
              </a>
              <a className="social-link" target="_blank" href="https://www.instagram.com/colossiclayton/">
                <img alt="Follow on Instagram" className="social-icon" src={InstagramImage} />
              </a>
            </div>
            <br />
            <p className="text-white">Copyright © {currentYear} by Clayton Whitehead</p>
          </div>
        </div>
      </div>
      <hr />
    </section>
  );
}

export default Footer;