import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import cwLogo from '../img/ClaytonWhitehead_LogoMark2025.svg';

function Header({ scrollToSection }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    
    // Always update background color behavior
    setIsScrolled(currentScrollY > 0);

    // Handle the hide/show behavior on mobile
    if (window.innerWidth < 992) {
      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(currentScrollY);
    } else {
      // Always visible on desktop
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  // Combine the classes, but keep navbar-fixed-top
  const headerClassName = `fixed-top ${isScrolled ? 'active' : ''} ${!isVisible ? 'header-hidden' : ''}`;

  const handleNavClick = (sectionId) => {
    if (location.pathname !== '/') {
      // If we're not on the home page, navigate home first
      navigate('/');
    }
    scrollToSection(sectionId);
  };

  return (
    <header id="cw_header" className={headerClassName}>
      <Navbar expand="lg">
        <Navbar.Brand href="#banner_section" onClick={() => handleNavClick('banner_section')}>
          <img className="logo" alt="Back to top" src={cwLogo} />
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="cwNavbar">
          <span className="navbar-toggler-icon color--white">
            <i className="material-icons">menu</i>
          </span>
        </Navbar.Toggle>

        <Navbar.Collapse id="cwNavbar">
          <Nav className="navbar-nav">
            <Nav.Item className="nav-item active">
              <Nav.Link href="#portfolio_section" onClick={() => handleNavClick('portfolio_section')}>
                Portfolio
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Nav.Link href="#about_section" onClick={() => handleNavClick('about_section')}>
                About
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header